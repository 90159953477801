@if (editor?.sequences?.length > 0) {
  <confect-split-view
    #split
    (specChange)="grid.setCanvas(); update()"
    [spec]="_editorMode === CreativeEditorMode.VIDEO ? videoSpec : imageSpec"
  >
    <ng-template split-content-tmp let-view="view">
      <div split-content [show]="view === 'canvas'" class="relative pt-14">
        @if (view === "canvas" && !loading) {
          <div
            id="canvas"
            class="z-10 relative h-full w-full"
            [ngClass]="{
              'video-mode': _editorMode === CreativeEditorMode.VIDEO,
              'image-mode': _editorMode !== CreativeEditorMode.VIDEO,
            }"
            #canvas
            (wheel)="scrollZoom($event)"
          >
            <ngx-creatives-edit-grid-v3
              #grid
              [editor]="editor"
              [live]="live"
              [aspect]="selectedResolution"
              [resolution]="currentLiveResolution"
              [zoomFactor]="currentZoom"
              (context)="
                rightClick($event, editor.layer ?? editor.multiSelectedLayers())
              "
              [showBox]="dropDown?.open || groupDropDown?.open"
              [editorMode]="_editorMode"
              [time]="editor.selectedTime"
              [scrollOffset]="scrollOffset"
              [allowSnap]="allowSnap"
            />
          </div>
          <div
            class="header"
            [ngClass]="{
              hidden: _editorMode === CreativeEditorMode.VIDEO,
            }"
          >
            @if (_formatSpec != null && _formats.length > 1) {
              <div class="flex items-center space-x-2">
                @for (format of _formats; track format.uuid; let i = $index) {
                  <div
                    tooltip
                    tooltipPosition="below"
                    [tooltipDisable]="
                      _currentFormat === format.uuid || this.formatsAllowed
                    "
                    tooltipText="<div class='w-56'>Upgrade to pro or higher to use formats</div>"
                    class="min-w-12 h-12 flex items-center justify-center rounded-md bg-white px-2"
                    [ngClass]="{
                      'cursor-default ring-2 ring-inset ring-primary text-gray-900':
                        _currentFormat === format.uuid,
                      'cursor-pointer': _currentFormat !== format.uuid,
                    }"
                    (click)="
                      _currentFormat !== format.uuid &&
                        changeFormat.emit(format.uuid);
                      editor.deselectLayer()
                    "
                  >
                    {{ _formatSpec[format.key].display }}
                  </div>
                }
                <!-- Disabled for now -->
                @if (
                  _formats.length < _formatSpecList.length - 2 &&
                  selectedResolution !== "custom" &&
                  this.formatsAllowed &&
                  false
                ) {
                  <div
                    class="w-12 h-12 flex items-center justify-center rounded ring-2 ring-inset ring-slate-300 group hover:bg-slate-200 hover:ring-slate-200 cursor-pointer"
                    dialogBox
                    dialogPosition="below"
                    dialogAlign="left"
                    [dialogTemplate]="formatSelection"
                  >
                    <confect-icon
                      icon="add"
                      iconClass="text-2xl text-slate-400 group-hover:text-slate-500"
                    />
                  </div>

                  <ng-template #formatSelection let-close="close">
                    <div class="w-52 flex flex-col">
                      @for (format of _formatSpecList; track format.key) {
                        @if (
                          format.key !== "video" &&
                          format.key !== "custom" &&
                          !format.hide
                        ) {
                          <div
                            class="w-full px-3 py-2 cursor-pointer hover:bg-slate-50"
                            (click)="addFormat.emit(format.key); close()"
                          >
                            {{ format.display }}
                          </div>
                        }
                      }
                    </div>
                  </ng-template>
                }
              </div>
            }
          </div>
          <div
            class="footer"
            [ngClass]="{
              'video-mode': _editorMode === CreativeEditorMode.VIDEO,
              'image-mode': _editorMode !== CreativeEditorMode.VIDEO,
            }"
          >
            <div class="flex items-center justify-between h-full">
              <div
                class="flex items-center justify-start h-full w-full gap-x-2"
              >
                @if (
                  allowResolutionChange &&
                  viewInit &&
                  defaultFormat === _currentFormat &&
                  (_formats == null || _formats.length === 1)
                ) {
                  <confect-select
                    class="w-40 cursor-pointer"
                    [options]="_formatSpecList"
                    bindLabel="display"
                    bindValue="key"
                    dropdownPosition="top"
                    [searchable]="false"
                    [(ngModel)]="selectedResolution"
                    (ngModelChange)="switchMainRes($event)"
                  />
                  @if (selectedResolution === "custom") {
                    <confect-icon-button
                      class="hover:opacity-80"
                      (click)="setCustomResolution()"
                      icon="settings_outlined"
                      iconClass="text-xl"
                    />
                  }
                }
              </div>

              <div class="flex items-center space-x-2">
                <confect-slider
                  class="w-60"
                  tooltip
                  tooltipText="Zoom canvas"
                  tooltipPosition="above"
                  [sliderInfo]="{
                    interval: { start: minZoom * 100, end: maxZoom * 100 },
                    stepSize: 1,
                    default: 100,
                  }"
                  [writable]="false"
                  [decimals]="0"
                  type="light"
                  [ngModel]="currentZoom * 100"
                  (ngModelChange)="
                    currentZoom = $event / 100; scrollOffset = { x: 0, y: 0 }
                  "
                />
              </div>
            </div>
          </div>
        }
      </div>
      <div
        split-content
        [show]="view === 'properties'"
        class="pr-2 pl-1 pb-2"
        [ngClass]="{
          'pt-16': _editorMode === CreativeEditorMode.VIDEO,
          'pt-1': _editorMode !== CreativeEditorMode.VIDEO,
        }"
      >
        @if (view === "properties") {
          <ngx-creative-edit-properties
            class="w-full h-full z-[400]"
            #propertiesBox
            [editorMode]="_editorMode"
            [editor]="editor"
            [live]="live"
            [specs]="specs"
            [helpEnabled]="helpEnabled"
            [extraSettings]="extraSettings"
            [(selectedPreviewFilters)]="selectedPreviewFilters"
            (selectedPreviewFiltersChange)="
              selectedPreviewFiltersChange.emit($event)
            "
            [previewFilterOptions]="previewFilterOptions"
            [(allowSnap)]="allowSnap"
            [showPreview]="showPreview"
            [mainFormat]="_currentFormat === defaultFormat"
            [format]="actualFormat"
            (syncLayer)="syncLayer.emit($event)"
          />
        }
      </div>
      <div
        split-content
        [show]="view === 'layers'"
        class="pr-2 pl-1 pt-16 pb-1"
      >
        @if (view === "layers") {
          <confect-card
            class="timeline-container image-mode shadow overflow-y-auto w-full h-full"
            [fullSize]="true"
          >
            <confect-card-body>
              <ngx-creatives-edit-timeline
                [editor]="editor"
                [live]="live"
                [specs]="specs"
                [showTimeline]="false"
                [raisedError]="raisedError"
                [allowProductActions]="!forceSingleProduct"
                (context)="rightClick($event.event, $event.layer)"
              />
            </confect-card-body>
          </confect-card>
        }
      </div>
      <div
        split-content
        [show]="view === 'timeline'"
        class="pl-2 pb-2 pr-1 pt-1"
      >
        @if (view === "timeline") {
          <confect-card
            class="timeline-container video-mode shadow overflow-y-auto w-full h-full"
            [withoutPadding]="true"
            [fullSize]="true"
          >
            <confect-card-body>
              <div class="w-full h-full">
                <ngx-creatives-edit-timeline
                  #timeline
                  [editor]="editor"
                  [live]="live"
                  [specs]="specs"
                  [showTimeline]="true"
                  [raisedError]="raisedError"
                  [allowProductActions]="!forceSingleProduct"
                  (context)="rightClick($event.event, $event.layer)"
                />
              </div>
            </confect-card-body>
          </confect-card>
        }
      </div>
    </ng-template>
  </confect-split-view>
}

<ng-template #previewWindow let-close="close">
  @if (previewShow) {
    <div class="h-[70vh] w-[80vh]">
      <ngx-creatives-preview-window
        #previewer
        [editor]="editor"
        [selectedFilters]="selectedPreviewFilters"
        [mode]="_editorMode"
        [resolution]="currentPreviewResolution"
        (renderError)="onRenderError($event)"
        (closeWindow)="close()"
      />
    </div>
  }
</ng-template>

<!-- <confect-slide-over
  title="Preview products"
  #previewProducts
  (cancel)="resetProductFilter()"
>
  <div class="flex flex-col items-center space-y-4 mx-4">
    <ngx-product-filter
      style="max-width: 100%"
      [filterOptions]="previewFilterOptions"
      [(selectedFilters)]="selectedPreviewFilters"
      (selectedFiltersChange)="productFiltersChanged()"
    />

    <confect-button
      class="w-full"
      [block]="true"
      type="white"
      size="large"
      (click)="resetProductFilter()"
      >Reset</confect-button
    >

    <confect-button
      class="w-full"
      [block]="true"
      size="large"
      (click)="applyProductFilter()"
      >Apply</confect-button
    >
  </div>
</confect-slide-over> -->
<ng-template #customResolutionModal let-extra="extra" let-close="close">
  <div class="text-center">
    <div class="grid grid-cols-2 gap-8">
      <div class="text-center">Width</div>
      <div class="text-center">Height</div>
      <confect-input [(ngModel)]="extra.size[0]" type="number" />
      <confect-input [(ngModel)]="extra.size[1]" type="number" />
    </div>
  </div>
  <div class="flex items-center justify-center space-x-8 mt-5">
    <confect-button (click)="close(false)" type="white">Cancel</confect-button>
    <confect-button
      (click)="applyCustomResolution(extra.size); close(true)"
      type="secondary"
      >Apply</confect-button
    >
  </div>
</ng-template>

<ng-template #noVideoSupport let-extra="extra" let-close="close">
  <div class="text-white w-52">
    @if (hasLicense) {
      Upgrade to the <span class="font-bold">Pro</span> plan or better to get
      access to video designs and catalogs.
      <div class="w-full flex justify-center mt-5">
        <confect-button type="white" (click)="extra.plans()">
          See Plans
        </confect-button>
      </div>
    } @else {
      This market is inactive and needs to be assigned a license for this
      feature to be available.
      <div class="w-full flex justify-center mt-5">
        <confect-button type="white" (click)="extra.markets()">
          Go to Markets
        </confect-button>
      </div>
    }
  </div>
</ng-template>

<ng-template #noVideoSupportRes let-extra="extra" let-close="close">
  <div class="text-white w-52">
    @if (hasLicense) {
      Upgrade to the <span class="font-bold">Pro</span> plan or better to get
      access to video designs and catalogs.
    } @else {
      This market is inactive and needs to be assigned a license for this
      feature to be available.
    }
  </div>
</ng-template>
