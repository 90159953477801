import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { CreativesTemplatesComponent } from "@components/designs/creatives-templates/creatives-templates.component";

import { CreativesDemoComponent } from "./creatives-demo/creatives-demo.component";
import { CreativesDesignsOverviewComponent } from "./creatives-designs-overview/creatives-designs-overview.component";
import { CreativesEditV2Component } from "./creatives-edit-v2/creatives-edit-v2.component";
import { CreativesEditComponent } from "./creatives-edit/creatives-edit.component";
import { CreativesNewComponent } from "./creatives-new/creatives-new.component";

const routes: Routes = [
  {
    path: "",
    title: "Designs - Confect.io",
    component: CreativesDesignsOverviewComponent,
  },

  {
    path: "new",
    title: "New Design - Confect.io",
    component: CreativesNewComponent,
  },
  {
    path: "edit",
    title: "Edit Design - Confect.io",
    component: CreativesEditV2Component,
  },
  {
    path: "edit/:id",
    component: CreativesEditV2Component,
  },
  {
    path: "demo",
    title: "Design Demo - Confect.io",
    component: CreativesDemoComponent,
  },
  {
    path: "templates",
    title: "Design Templates - Confect.io",
    component: CreativesTemplatesComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CreativesRoutingModule {}

export const routedComponents = [
  CreativesEditComponent,
  CreativesNewComponent,
  CreativesDemoComponent,
];
