import { CreativeLayer, CreativeLayerType } from "@core/models/creative.types";

import { Subject } from "rxjs";

import { CreativesEditService } from "./creatives-edit.service";

class Stack<T> {
  private _store: T[] = [];
  maxLength: number;

  constructor(maxLength: number) {
    this.maxLength = maxLength;
  }

  push(val: T) {
    // If Stack is too big, we remove the oldest value
    if (this._store.length === this.maxLength) {
      this._store.splice(0, 1);
    }

    this._store.push(val);
  }
  pop(): T | undefined {
    return this._store.pop();
  }
  size(): number {
    return this._store.length;
  }

  flush(): void {
    this._store = [];
  }
}

enum HistorySnapshotType {
  SPEC = "spec",
  LAYER = "layer",
}

interface HistorySnapshot {
  snapshot: string;
  snapshotType: HistorySnapshotType;
}

interface HistoryPoint {
  prevState: HistorySnapshot;
  newState: HistorySnapshot;
}

export class CreativesUndoRedoService {
  editor: CreativesEditService;

  undoStack = new Stack<HistoryPoint>(30);
  redoStack = new Stack<HistoryPoint>(30);

  prevState: HistorySnapshot;

  // Booleans for binding to the UI
  canUndo = false;
  canRedo = false;

  private createdHistoryPoint = new Subject<HistoryPoint>();
  createdHistoryPoint$ = this.createdHistoryPoint.asObservable();

  constructor(editor: CreativesEditService) {
    this.editor = editor;
  }

  private refreshBindingVariables() {
    // We always keep the current state, therefore > 1 means we have things we can go back to
    this.canUndo = this.undoStack.size() > 0;
    this.canRedo = this.redoStack.size() > 0;
  }

  private snapshotCleanup() {
    this.editor.layerChangedSource.next(true);
    if (this.editor.layer == null) {
      return;
    }
    const currentLayerIdent = this.editor.layer.identifier;

    // Check if current layer is still a layer after restoring snapshot

    const newSelectedLayer = Array.from(this.editor.iterateLayers(true)).find(
      (l) => l.identifier === currentLayerIdent,
    );

    if (newSelectedLayer) {
      this.editor.selectLayer(newSelectedLayer.identifier);
    } else {
      this.editor.deselectLayer();
    }
  }

  private applySnapshot(snapshot: HistorySnapshot) {
    switch (snapshot.snapshotType) {
      case HistorySnapshotType.LAYER:
        const newLayer: CreativeLayer = JSON.parse(snapshot.snapshot);

        const layers = this.editor.sequence.layers;

        let outerIndex: number = null;
        let innerIndex: number = null;

        for (
          let outerLayerIndex = 0;
          outerLayerIndex < layers.length;
          outerLayerIndex++
        ) {
          const layer = layers[outerLayerIndex];

          if (layer.identifier === newLayer.identifier) {
            outerIndex = outerLayerIndex;
            break;
          }

          if (newLayer.type == CreativeLayerType.GROUP) {
            continue;
          }

          if (layer.type === CreativeLayerType.GROUP) {
            layer.layers.forEach((innerLayer, innerLayerIndex) => {
              if (innerLayer.identifier === newLayer.identifier) {
                outerIndex = outerLayerIndex;
                innerIndex = innerLayerIndex;
                return;
              }
            });
            if (outerIndex && innerIndex) {
              break;
            }
          }
        }

        if (outerIndex != null && innerIndex != null) {
          this.editor.sequence.layers[outerIndex].layers[innerIndex] = newLayer;
          this.editor.specChangeSource.next(this.editor.getSpec());
        } else if (outerIndex != null) {
          this.editor.sequence.layers[outerIndex] = newLayer;
          this.editor.specChangeSource.next(this.editor.getSpec());
        }
        break;
      case HistorySnapshotType.SPEC:
        const newLayers: CreativeLayer[] = JSON.parse(snapshot.snapshot);
        this.editor.sequence.layers = newLayers;
        this.editor.specChangeSource.next(this.editor.getSpec());

        break;
    }
    this.snapshotCleanup();
  }

  undo() {
    if (!this.canUndo) {
      return;
    }

    const point = this.undoStack.pop();

    this.applySnapshot(point.prevState);
    this.redoStack.push(point);

    this.refreshBindingVariables();
  }

  redo() {
    if (!this.canRedo) {
      return;
    }

    const point = this.redoStack.pop();

    this.applySnapshot(point.newState);
    this.undoStack.push(point);

    this.refreshBindingVariables();
  }

  private snapshot(action: HistoryPoint) {
    // Push the new action as something we can undo
    this.undoStack.push(action);

    // As soon as we snapshot something new, we cannot use the redos anymore
    this.redoStack.flush();

    // Refresh binding variables so UI knows if we can undo/redo
    this.refreshBindingVariables();

    this.createdHistoryPoint.next(action);
  }

  private layerSnapshot(layer?: CreativeLayer): HistorySnapshot {
    return {
      snapshot: JSON.stringify(layer ?? this.editor.layer),
      snapshotType: HistorySnapshotType.LAYER,
    };
  }

  private specSnapshot() {
    return {
      snapshot: JSON.stringify(this.editor.sequence.layers),
      snapshotType: HistorySnapshotType.SPEC,
    };
  }

  beginLayerSnapshot(layer: CreativeLayer = null) {
    // if (this.prevState) {
    //   console.log("Found unfinished snapshot, this is pretty bad");
    // }
    this.prevState = this.layerSnapshot(layer);
  }

  beginSpecSnapshot() {
    // if (this.prevState) {
    //   console.log("Found unfinished snapshot, this is pretty bad");
    // }
    this.prevState = this.specSnapshot();
  }

  private finishSnapshot(currentState: HistorySnapshot) {
    if (!this.prevState) {
      // console.log(
      //   "Trying to finish a snapshot without having begun one is an error!"
      // );
      return;
    }

    if (this.prevState.snapshot === currentState.snapshot) {
      this.prevState = null;
      return;
    }

    const prevState = this.prevState;
    this.prevState = null;

    if (prevState.snapshotType !== currentState.snapshotType) {
      // console.log(
      //   "Prev and current snapshot is not the same type, this is an error, aborting"
      // );
      return;
    }

    this.snapshot({
      prevState: prevState,
      newState: currentState,
    });
  }

  finishLayerSnapshot(layer?: CreativeLayer) {
    const current = this.layerSnapshot(layer);
    this.finishSnapshot(current);
  }

  finishSpecSnapshot() {
    const current = this.specSnapshot();
    this.finishSnapshot(current);
  }
}
