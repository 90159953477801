<confect-card
  class="properties-container w-full h-full shadow overflow-y-auto"
  [ngClass]="{
    'video-mode': editorMode === CreativeEditorMode.VIDEO,
    'image-mode': editorMode !== CreativeEditorMode.VIDEO,
  }"
  [withoutPadding]="true"
  [fullSize]="true"
>
  <confect-card-body>
    @if (
      editor?.selectedLayerIdentifier == null || editor?.layer?.type !== "layer"
    ) {
      <confect-tab-menu
        [tabs]="showPreview ? tabsShowProducts : tabsNoPreview"
        (tabChange)="$event === 'product' && this.productCount()"
      >
        <ng-template tab-content-tmp let-currentTab="currentTab">
          <div tab-content [show]="currentTab === 'inspiration'">
            <div class="flex flex-col px-2 space-y-4 mb-40">
              @for (section of academyArticles; track section.title) {
                <div>
                  <h4>{{ section.title }}</h4>
                  <div class="text-xs">{{ section.subtitle }}</div>
                  <div class="grid grid-cols-2 gap-2 mt-2">
                    @for (article of section.articles; track article.href) {
                      <div
                        class="rounded-md overflow-hidden ring-2 ring-inset ring-gray-200 cursor-pointer hover:bg-slate-100 group hover:text-gray-900"
                        (click)="goToUrl(article.href)"
                      >
                        <div>
                          <img
                            [src]="article.image"
                            class="object-contain bg-slate-100 group-hover:opacity-75"
                          />
                        </div>
                        <div class="text-sm px-2 py-2">
                          {{ article.title }}
                        </div>
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
          <div tab-content [show]="currentTab === 'elements'">
            @if (currentTab === "elements") {
              <div class="mb-40">
                <ngx-creatives-elements-sidebar
                  dialogBoxTarget
                  dialogPosition="above"
                  targetID="elements"
                  [editor]="editor"
                  (elementPicked)="elementPicked.emit()"
                />
              </div>
            }
          </div>

          <div tab-content [show]="currentTab === 'product'" class="px-5">
            <div class="flex flex-col divide-y">
              @if (showPreview) {
                <div class="w-full flex items-center justify-between">
                  <div class="text-sm select-none">
                    Products: {{ filteredCount }} of {{ totalCount }}
                  </div>
                  <div class="flex items-center justify-end">
                    <confect-button
                      class="w-full"
                      type="subtle"
                      size="xsmall"
                      (click)="randomProduct()"
                      tooltip
                      tooltipText="Shuffle"
                      tooltipPosition="above"
                      ><confect-icon icon="shuffle" iconClass="text-lg"
                    /></confect-button>
                    <confect-button
                      class="w-full"
                      type="subtle"
                      size="xsmall"
                      tooltip
                      tooltipText="Reset"
                      tooltipPosition="above"
                      (click)="resetProductFilter()"
                      ><confect-icon icon="restore" iconClass="text-lg"
                    /></confect-button>
                  </div>
                </div>
                <ngx-product-filter
                  class="mb-40"
                  style="max-width: 100%"
                  [filterOptions]="previewFilterOptions"
                  [(selectedFilters)]="selectedPreviewFilters"
                  (selectedFiltersChange)="
                    productFiltersChanged(); applyProductFilter()
                  "
                />
              }
            </div>
          </div>
          <div tab-content [show]="currentTab === 'general'">
            <confect-toggle
              [title]="'Layer Snapping'"
              [(ngModel)]="allowSnap"
            />
          </div>
        </ng-template>
      </confect-tab-menu>
    }

    @if (editor?.cell != null && editor?.layer?.type === "layer") {
      <ngx-spec-group-handler
        [specGroups]="
          editor.activeSelectionType == null
            ? []
            : specs[editor.activeSelectionType]
              ? specs[editor.activeSelectionType]
              : []
        "
        [specSettings]="editor.cell.config"
        [editor]="editor"
        [editorMode]="editorMode"
        [helpEnabled]="helpEnabled"
        [extraSettings]="extraSettings"
        [raisedError]="
          raisedError?.error_info?.layer === editor.layer.identifier
            ? raisedError
            : null
        "
        [mainFormat]="mainFormat"
        [format]="format"
        (syncLayer)="syncLayer.emit($event)"
      />
    }
  </confect-card-body>
</confect-card>
