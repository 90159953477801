import { Location } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnInit,
  OnDestroy,
  AfterViewInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { DesignsService } from "@core/api/designs.service";
import {
  CreativeEditorMode,
  CreativePreviewSettings,
} from "@core/models/creative.types";
import { AccessService } from "@core/services/access.service";
import {
  CreativesEditMasterService,
  Design,
  DesignFormatSpec,
} from "@core/services/creatives/creatives-edit-master.service";
import { CreativesEditService } from "@core/services/creatives/creatives-edit.service";
import { CreativesLiveService } from "@core/services/creatives/creatives-live.service";
import { CustomFieldsService } from "@core/services/custom-fields.service";
import { HomeActionsService } from "@core/services/home-actions-service";
import { HotjarService } from "@core/services/hotjar.service";
import { ProctorService } from "@core/services/proctor.service";
import { UserService } from "@core/services/user.service";

import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";

import { Observable, Subject, takeUntil } from "rxjs";

import { resolutions } from "../../creatives-resolutions";
import { CreativeError } from "../../types/creative-error";
import { CreativesEditEditorComponent } from "../creatives-edit-editor/creatives-edit-editor.component";

@Component({
  selector: "ngx-creatives-edit-pop-up-editor",
  templateUrl: "./creatives-edit-pop-up-editor.component.html",
  styleUrls: ["./creatives-edit-pop-up-editor.component.scss"],
  standalone: false,
})
export class CreativesEditPopUpEditorComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public readonly CreativeEditorMode = CreativeEditorMode;
  unsubscribe$ = new Subject();

  selectedPreviewFilters = {};
  previewFilterOptions = [];
  selectedResolution = "1_1";
  resolutions = resolutions;
  introFinished: boolean = false;
  raisedError: CreativeError = null;

  // Id of creative we are editing
  @Input() id = null;
  @Input() startFormat;

  @Output() afterClose = new EventEmitter<any>();

  videoSupport = false;

  hasUnsavedChanges = false;

  // Extra settings
  extraSettings = { minimalUser: true };
  helpEnabled = true;

  // Recipe callback when we wish to go to recipes after save
  recipeCreateCallback = null;

  // Creative spec editor instance
  master: CreativesEditMasterService;
  editor: CreativesEditService = null;
  live: CreativesLiveService = null;

  // Sidebar content
  specs: any = {};

  demoMode = false;
  demoModePreviewClicked = false;

  // Video loading / preview
  currentProgress = 0;
  videoURL = null;

  isShown: boolean = false;

  formats: Record<string, DesignFormatSpec> = {
    "1_1": { display: "1:1", key: "1_1", resolution: [1080, 1080] },
  };
  formatsList: DesignFormatSpec[] = [
    { display: "1:1", key: "1_1", resolution: [1080, 1080] },
  ];

  @ViewChild("editorComponent") editorRef: CreativesEditEditorComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private designsService: DesignsService,
    private proctor: ProctorService,
    private accessService: AccessService,
    private ref: ChangeDetectorRef,
    private fs: CustomFieldsService,
    private us: UserService,
    private ps: CPopupModalService,
    private ha: HomeActionsService,
    private hj: HotjarService,
  ) {
    this.introFinished = this.accessService.current?.account.state.has_designs;
  }

  // Init
  ngOnInit() {
    this.initializeEditor(this.id);

    this.us.me(false).subscribe((me) => {
      this.demoMode = me.anon === true;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isShown = true;
    }, 50);
  }

  ngOnDestroy() {
    this.live?.destroy();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  syncLayer(layer: string, key: string) {
    this.master.syncLayer(layer, key);
  }

  loadEditorService(design: Design = null) {
    const ps: CreativePreviewSettings = design ? design.preview_settings : {};

    // Saved preview filters
    const f = ps.preview_filters;
    this.selectedPreviewFilters = f ? f : {};

    const company = this.accessService.current.company.id;
    const account = this.accessService.current.account.id;
    const proctor = this.proctor.info.proctorModeActive()
      ? this.proctor.info.currentProctorCompany.id
      : null;

    this.master = new CreativesEditMasterService(
      design,
      this.formats,
      company,
      account,
      proctor,
      this.accessService.current.account.has_feed,
    );

    setTimeout(() => {
      this.editor = this.master.editor;
      this.live = this.master.live;

      this.setupCallbacks();

      this.editorRef.configureResolution();

      if (this.startFormat != null) {
        const formatUUID = this.master.design.spec.formats.find(
          (format) => format.key === this.startFormat,
        )?.uuid;

        if (formatUUID) {
          this.master.changeFormat(formatUUID);
        } else {
          this.master.addFormat(this.startFormat);
        }
      }
    });
  }

  initializeEditor(id: number) {
    this.loadEditorSettings();
    this.loadPreviewFilterOptions();

    // Feature enables
    if (this.accessService.hasFeature("video")) {
      this.extraSettings.minimalUser = false;
      this.videoSupport = true;
      // this.editorMode = "video";
    }

    // Video spec load
    this.designsService
      .getVideoSpecs()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.specs = res.specs;
        this.formatsList = res.formats;
        if (this.videoSupport) {
          this.formatsList.push({
            display: "Video",
            key: "video",
            resolution: [1080, 1080],
          });
        }
        this.formats = {};
        res.formats.forEach((format) => {
          this.formats[format.key] = format;
        });
      });

    // Load creative from API
    this.id = id;
    this.designsService
      .getCreatives(this.id, true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res) => {
          this.loadEditorService(res);
          // this.refreshLayerPreview();
        },
        error: (_err) => {
          this.router.navigate(["/designs"]);
        },
      });
  }

  // Hooking up event changes when creative is edited
  setupCallbacks() {
    this.master.formatChange$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (_) => {
        this.editorRef.resolutionChanged();
      },
    });

    this.editor.layerChanged$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((layer) => {
        // Do a change detection on layer change, to make sure all settings and defaults sync
        this.ref.detectChanges();
      });

    // Live on backend raises error
    this.live.errorRaised$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((error) => {
        // this.raisedError = error;
      });

    this.editor.history.createdHistoryPoint$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_) => {
        this.hasUnsavedChanges = true;
      });
  }

  // Check if we have a recipe callback as query param and set it
  checkRecipeCallback() {
    const qpm = this.route.snapshot.queryParamMap;
    if (qpm.has("recipeCallback")) {
      this.recipeCreateCallback = qpm.get("recipeCallback");
    }
  }

  // Load user-specific editor settings
  loadEditorSettings() {
    const h = localStorage["editorHelpEnabled"];
    if (h === undefined) {
      return;
    }
    this.helpEnabled = h === "true";
  }
  // Product preview filters
  loadPreviewFilterOptions() {
    this.fs
      .getFields()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.previewFilterOptions = res;
      });
  }

  freeTrial() {
    window.open("https://confect.io/start-free-trial/", "_blank");
  }

  saveWarning() {
    this.ps
      .warning({
        title: "This design is used in other places.",
        text: "If you save, any changes made will be applied globally. Duplicate the design to only apply the changes for this instance.",
        confirmText: "Duplicate Design",
        cancelText: "Save Design",
      })
      .outputs["modalClosed"].asObservable()
      .subscribe({
        next: (res) => {
          if (res) {
            this.ps
              .input({
                title: "Choose new name",
                text: "Set a name for the changed design, so you can tell them apart.",
                value: JSON.parse(JSON.stringify(this.editor.name)),
              })
              .outputs["modalClosed"].asObservable()
              .subscribe({
                next: (name) => {
                  this.designsService.duplicateCreative(this.id).subscribe({
                    next: (res) => {
                      this.save(false, { name: name, id: res.id });
                    },
                  });
                },
              });
          } else {
            this.save();
          }
        },
      });
  }

  // Save creative
  async save(callback = false, duplicate: { name: string; id: number } = null) {
    if (this.editor.name === "") {
      return;
    }

    const creative: Design = this.master.getCleanDesign();
    creative.preview_settings = {
      preview_filters: this.master.selectedPreviewFilters,
      selectedResolution: this.master.selectedResolution.replace("_", ":"),
      resolution: this.editorRef.currentPreviewResolution,
      editorMode: this.master.editorMode,
    };

    if (this.id != null && duplicate != null) {
      creative.id = duplicate.id;
      creative.name = duplicate.name;
    } else {
      creative.name = this.editor.name;
    }

    this.designsService
      .saveCreative(creative)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res) => {
          // Triggering a home update here, should be implemented later as a global event service
          this.ha.getData(true).subscribe();

          this.hasUnsavedChanges = false;

          this.ps
            .success({
              title: "Saved!",
              autoCloseTimeout: 1500,
            })
            .outputs["modalClosed"].asObservable()
            .subscribe({
              next: (res) => {
                this.afterClose.emit(creative.id);
              },
            });
          setTimeout(() => {
            this.validateVideo();
          }, 1500);
        },
        error: (err) => {},
        complete: () => {},
      });
  }

  // Observable for rendering a video job with progres
  renderJobObservable(jobID) {
    return new Observable((observer) => {
      const action = setInterval(() => {
        this.designsService
          .getVideoStatus(jobID)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res) => {
            this.currentProgress = res.progress;
            if (res.status === "finished") {
              observer.next(res.result.url);
              clearInterval(action);
              observer.complete();
            } else if (res.status === "failed") {
              observer.error(res.error);
              clearInterval(action);
              observer.complete();
            }
          });
      }, 1000);
    });
  }

  validateVideo() {
    this.raisedError = null;

    const spec = this.editor.getSpec();
    const size = [50, 50];

    this.designsService
      .enqueueVideoDemo(spec, size, false, this.selectedPreviewFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res1) => {
        this.renderJobObservable(res1.job_id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: (_) => {},
            error: (error) => {
              this.raisedError = error;

              this.ps.error({
                title: "Saved with error",
                text: error.message,
                autoCloseTimeout: 4000,
              });
            },
          });
      });
  }

  closeWarning() {
    if (!this.hasUnsavedChanges) {
      this.afterClose.emit();
      return;
    }

    this.ps
      .warning({
        title: "You have unsaved changes",
        confirmText: "Close Anyway",
        cancelText: "Keep Editing",
      })
      .outputs["modalClosed"].asObservable()
      .subscribe({
        next: (res) => {
          if (res) {
            this.afterClose.emit();
          }
        },
      });
  }

  close = () => {
    this.afterClose.emit();
  };
}
